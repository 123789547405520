html,
body {
  font-size: 14px;
  color: #333;
  height: 100%;
  position: relative;
}
* {
  box-sizing: border-box;
}
.sp {
  display: none;
}
body {
  font-family: Verdana, Roboto, 'Droid Sans', '游ゴシック', YuGothic, 'メイリオ',
    Meiryo, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN',
    'ＭＳ Ｐゴシック', sans-serif;
  background-image: url(/img/bgpattern.svg);
  background-repeat: repeat;
  background-position: center top;
}
#header {
  background: #000;
  box-shadow: 0 2px 8px rgba(30, 30, 30, 0.3);
  left: 0;
  line-height: 1;
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1000px;
  height: 56px;
  z-index: 2400;
}
#menu {
  float: left;
  margin: 13px auto 0 13px;
}
#menu a img {
  opacity: 0.8;
  width: auto;
  height: 30px;
}
#menu a:nth-child(1) {
  width: 190px;
  height: 38px;
}
#menu a:nth-child(2) {
  width: 120px;
  height: 30px;
  margin-left: 5px;
}
#menu a:nth-child(3) {
  width: 150px;
  height: 30px;
  margin-left: 5px;
}
#menu a:nth-child(4) {
  width: 130px;
  height: 30px;
  margin-left: 5px;
}
#menu a:nth-child(5) {
  display: none;
}
#menu a:hover img {
  opacity: 1;
  transition: 0.5s;
}
#stateus {
  margin: 8px 8px 0 auto;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
} /* border: 1px solid #ff0; */
#account div,
#ticket div {
  text-align: center;
  position: relative;
  color: #fff;
  line-height: 38px;
  display: block;
  border: 2px solid #ccc;
  border-radius: 6px;
}
#account {
  flex: 0 0 175px;
  margin: 0 0 0 5px;
  letter-spacing: -0.03em;
}
#ticket {
  flex: 0 0 245px;
  margin: 0 0 0 5px;
  letter-spacing: -0.05em;
}
#account span,
#ticket span {
  font-weight: bold;
  color: #ff0;
}
#lang {
  flex: 0 0 70px;
  margin: 0 0 0 5px;
}

/*iconフォント行揃え iconmonstr用*/
p > i,
button > i {
  /* display: inline-flex !important; vertical-align: middle !important; */
}

/*clearfix*/
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

/*a*/
a {
  text-decoration: none;
  color: #0e38a2;
}
a.textLink {
  text-decoration: none;
}
a.textLink:hover {
  color: #990000;
}
a.textLink::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f360';
}
a.textLink {
  position: relative;
}
a.textLink::before {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ee0000;
  transform-origin: bottom right;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}
a.textLink:hover::before {
  transform-origin: bottom left;
  transform: scaleX(1);
}

#topImageWrap {
}
#pageWrap {
  position: relative;
  padding: 56px 0 0 0;
  min-height: calc(100vh - 17em);
  min-width: 1024px;
}
#topTri {
  display: block;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 33px;
  left: calc(50% - 23px);
  z-index: 2;
}
#contentsWrap,
.contentsWrap {
  width: 980px;
  margin: 0 auto 0;
  position: relative;
  text-align: left;
}
#topbg1 {
  background: url(/img/top/gradbg1.png) repeat-x top left;
  padding: 5px 0 56px;
}
#topbg2 {
  background: url(/img/top/gradbg2.png) repeat-x top left;
  padding: 5px 0 56px;
}
#topbg3 {
  background: url(/img/top/gradbg3.png) repeat-x top left;
  padding: 5px 0 56px;
}

section {
  margin: 2em 0 0;
  position: relative;
}
section h1 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: #fff;
  padding: 10px 100px;
  position: relative;
  border-radius: 18px;
  background-color: #333;
  background-size: cover;
  text-shadow: 1px 1px 2px #333;
  margin: 2em auto 1em;
  width: calc(100% - 40px);
  text-align: center;
}
section h1::before {
  content: url('/img/kumo.svg');
  position: absolute;
  top: -15px;
  left: -20px;
}
section h1::after {
  content: url('/img/kumoR.svg');
  position: absolute;
  top: -15px;
  right: -20px;
}
section p {
  font-size: 1em;
  margin: 0.5em 0 0;
  padding: 0;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-wrap: break-word;
  /*text-align: justify;*/
}

#footer {
  background-color: #000;
  width: 100%;
  height: 15em;
  margin-top: 2em;
  position: relative;
  overflow: hidden;
}
#footerWrap {
  width: 980px;
  margin: 3em auto 0;
  height: 11em;
  position: relative;
}
#footerLinkWrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
}
#footerLinkWrap > #footerLink {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
  width: 100%;
}
#footerLinkWrap > #footerLink > li {
  flex: 0 0 auto;
  display: block;
  text-align: center;
  margin: 0 2em;
}
#footerLinkWrap > #footerLink > li a,
#footerLinkWrap > #footerLink > li a:visited {
  color: #ccc;
  text-decoration: none;
  line-height: 2em;
  font-size: 1.2em;
}
#footerLinkWrap > #footerLink > li a:hover {
  color: #fff;
  text-decoration: none;
}
#footerWrap > #copy {
  font-size: 0.8em;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: 2em;
  color: #fff;
}
#footerCaution {
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  margin-top: 2em;
}

.fortuneBox {
  display: flex;
}
.fortuneBoxL {
}
.fortuneBoxL h2 {
  margin-bottom: 5px;
}
.fortuneFortune {
  margin-bottom: 5px;
}
.fortuneBio {
  margin-bottom: 5px;
}
.fortuneGraph {
  background: url(/img/washi.jpg) center center no-repeat;
}
.fortuneGraphParts {
}
.fortuneBoxR {
  padding-left: 2em;
}
.fortuneBoxR p {
}

.assetPreviewBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 1em auto 0;
  width: 90%;
}
.assetPreviewBox li {
  flex: 0 0 23%;
  padding: 1%;
  margin: 1%;
  background-color: #fff;
  border: 1px solid #ccc;
}
.assetPreviewBox li div {
  width: auto;
  height: 150px;
  background: center center / contain no-repeat;
}
.assetPreviewBoxBtn {
  position: absolute;
  top: 105px;
  left: 0;
  text-align: center;
  width: 100%;
}

.btnLL {
  border: 8px solid #111;
  font-size: 5em;
  border-radius: 14px;
}
.btnL {
  border: 6px solid #111;
  font-size: 4em;
  border-radius: 12px;
}
.btnM {
  border: 5px solid #111;
  font-size: 2em;
  border-radius: 10px;
}
.btnS {
  border: 4px solid #111;
  font-size: 1.5em;
  border-radius: 8px;
}
.btnLL,
.btnL,
.btnM,
.btnS {
  background-color: #fff;
  font-weight: bold;
  text-align: center;
  margin: 1em auto 0;
  padding: 5px 2em;
  display: block;
  position: relative;
  z-index: 1;
  transition: 0.25s;
}
.btnLL:hover,
.btnL:hover,
.btnM:hover,
.btnS:hover {
  background-color: #111;
  color: #ff0;
}
.btnLL i,
.btnL i,
.btnM i,
.btnS i {
  font-size: 1em;
}

.btnLL.disabled,
.btnL.disabled,
.btnM.disabled,
.btnS.disabled {
  border-color: #999;
  color: #999;
  cursor: default;
}
.btnL.disabled:hover,
.btnM.disabled:hover,
.btnS.disabled:hover {
  background-color: #fff;
  border-color: #999;
  color: #999;
  cursor: default;
}

#birthday {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  margin: 1em 0 0;
  width: 60%;
  position: absolute;
  z-index: 2;
  left: 20%;
}
#year,
#month,
#day,
#gender {
  width: 33%;
}

#category {
  width: 300px;
  position: absolute;
  z-index: 12;
  right: 1%;
  border: 1px solid #aaa;
  border-radius: 3px;
}
#category span img {
  height: 1em;
  width: auto;
  margin-right: 1em;
}

.assetBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 6em auto 0;
}
.assetBox li {
  flex: 0 0 23%;
  border: 4px solid #333;
  box-shadow: 0px 1px 3px #333;
  background-color: #fff;
  margin: 1%;
}
.assetBox li .assetCategory {
  background-color: #333;
  font-size: 1em;
  line-height: 1.2em;
  padding: 5px;
  color: #eee;
}
.assetBox li .assetCategory img {
  height: 1em;
  width: auto;
  margin: 0 0.5em 5px 0; /*iconフォント行揃え*/
  display: inline-flex !important;
  vertical-align: middle !important;
}
.assetBox li .assetImage {
  text-align: center;
  position: relative;
}
.assetBox li .assetImage img {
  width: 180px;
  height: 180px;
  object-fit: contain; /* cover contain scale-down */
  margin: 5%;
  text-align: center;
}
.assetBox li .assetName {
  text-align: center;
  color: #333;
  width: 180px;
  margin: 0 auto;
  height: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis; /* Safari */
  -o-text-overflow: ellipsis; /* Opera */
}
.assetBox li .assetData {
  background-color: #333;
  font-size: 1em;
  padding: 5px;
  color: #eee;
  height: calc(2.4em + 10px);
}
.assetBox li .assetData p {
  padding: 0;
  font-size: 0.8em;
  line-height: 1.3;
  max-height: 2.4em;
  overflow: hidden;
  text-align: left;
}

/* ラベル部分 右上に表示 */
.soldout::before {
  content: '';
  top: 0;
  right: 0;
  border-bottom: 4em solid transparent;
  border-right: 4em solid #999999; /* ラベルの色はここで変更 */
  position: absolute;
  z-index: 10;
}
.soldout::after {
  content: 'SOLD';
  display: block;
  top: 11px;
  transform: rotate(45deg);
  color: #fff; /* 文字色はここで変更 */
  font-weight: bold;
  right: -1px;
  position: absolute;
  z-index: 11;
}
.newitem::before {
  content: '';
  top: 0;
  right: 0;
  border-bottom: 4em solid transparent;
  border-right: 4em solid #ff0000; /* ラベルの色はここで変更 */
  position: absolute;
  z-index: 10;
}
.newitem::after {
  content: 'NEW';
  display: block;
  top: 11px;
  transform: rotate(45deg);
  color: #fff; /* 文字色はここで変更 */
  font-weight: bold;
  right: 1px;
  position: absolute;
  z-index: 11;
}
.hotitem::before {
  content: '';
  top: 0;
  right: 0;
  border-bottom: 4em solid transparent;
  border-right: 4em solid #ff6600; /* ラベルの色はここで変更 */
  position: absolute;
  z-index: 10;
}
.hotitem::after {
  content: 'HOT';
  display: block;
  top: 10px;
  transform: rotate(45deg);
  color: #fff; /* 文字色はここで変更 */
  font-weight: bold;
  right: 3px;
  position: absolute;
  z-index: 11;
}

.footerAD {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 2em auto 0;
}
.footerAD a {
  /*4 flex: 0 0 23%; margin: 0;*/
  /*3*/
  flex: 0 0 32%;
  margin: 0;
}
.footerAD a img {
  width: 100%;
  height: auto;
}

section.error {
  width: 500px;
  margin: 0 auto;
  text-align: center;
}
section.error img {
  width: 300px;
  height: auto;
  margin: 4em 0 2em;
}
section.error h2 {
  font-size: 4em;
}
section.error p {
  text-align: center;
  margin-bottom: 2em;
}

section.wallet {
  text-align: center;
}
section.wallet h2 {
  font-size: 2em;
  margin: 6em 0;
}
section.wallet h3 {
  font-size: 2em;
  margin: 2em 0;
}

.colmn3master {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 2em auto 0;
  width: 100%;
  position: relative;
}
.colmn3left > .assetBox,
.colmn3right > .assetBox {
  margin: 0 auto;
}
.colmn3left,
.colmn3right {
  flex: 0 0 226px;
  margin: 0;
}
.colmn3center {
  flex: 0 0 528px;
  margin: 0;
  overflow: hidden;
}
.colmn3left > .assetBox li,
.colmn3right > .assetBox li {
  flex: 0 0 100%;
  margin: 0 0 20px;
}
.colmn3left > .assetBox li.banner,
.colmn3right > .assetBox li.banner {
  flex: 0 0 100%;
  margin: 0 0 20px;
  border: none !important;
  box-shadow: none !important;
  background-color: none !important;
}
.colmn3left > .assetBox li.banner img,
.colmn3right > .assetBox li.banner img {
  width: 100%;
  margin: 0;
}

.colmn3center {
  padding: 2em;
}
.colmn3center p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: justify;
}
.transactionStatus {
  margin: 6em 0;
}
.transactionStatus i {
  font-size: 10em;
  text-align: center;
  display: block;
}
.transactionStatus p {
  text-align: center;
  margin-top: 1em;
  font-weight: bold;
  font-size: 1.1em;
}

.fortuneWrap {
  padding: 5px 0 20px;
  background-image: url(/img/fortune-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-width: 980px;
}
section.fortuneSection {
  margin: 0 auto !important;
  width: 980px !important;
  position: relative;
}
.fortuneCatch {
  width: 325px;
  height: 162px;
  margin: 0 auto;
}

.topCol11 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol11 li:nth-child(1) {
  flex: 0 0 45%;
  margin: 0;
}
.topCol11 li:nth-child(2) {
  flex: 0 0 55%;
  margin: 0;
}
.topCol11 li img {
  width: 100%;
  height: auto;
}
.topCol11ul {
  margin: 10px auto 0 50px;
  list-style-type: disc;
}
.topCol12 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol12 li:nth-child(1) {
  flex: 0 0 50%;
  margin: 0;
  padding-right: 1em;
}
.topCol12 li:nth-child(2) {
  flex: 0 0 50%;
  margin: 0;
}
.topCol12 li img {
  width: 100%;
  height: auto;
}
.topCol13 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol13 li:nth-child(1) {
  flex: 0 0 35%;
  margin: 0;
}
.topCol13 li:nth-child(2) {
  flex: 0 0 65%;
  margin: 0;
  padding-left: 1em;
}
.topCol13 li img {
  width: 100%;
  height: auto;
}

.topCol21 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol21 li:nth-child(1) {
  flex: 0 0 55%;
  margin: 0;
}
.topCol21 li:nth-child(2) {
  flex: 0 0 45%;
  margin: 0;
}
.topCol21 li img {
  width: 100%;
  height: auto;
}
.topCol22 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol22 li:nth-child(1) {
  flex: 0 0 100%;
  margin: 0;
}
.topCol22 li:nth-child(2) {
  flex: 0 0 30%;
  margin: 0 auto;
}
.topCol22 li img {
  width: 100%;
  height: auto;
}
p.topCol23 {
  font-size: 1.2rem;
  width: 90%;
  margin: 2em auto 0;
  padding: 0;
}
.topCol24 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol24 li:nth-child(1) {
  flex: 0 0 60%;
  margin: 0;
}
.topCol24 li:nth-child(2) {
  flex: 0 0 20%;
  margin: 0;
  padding: 0 5px;
}
.topCol24 li:nth-child(3) {
  flex: 0 0 20%;
  margin: 0;
  padding: 0 5px;
}
.topCol24 li img {
  width: 100%;
  height: auto;
}
.topCol25 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0 auto 0;
  width: 100%;
  position: relative;
  font-size: 1.2rem;
}
.topCol25 li:nth-child(1) {
  flex: 0 0 20%;
  margin: 0;
}
.topCol25 li:nth-child(2) {
  flex: 0 0 80%;
  margin: 0;
  padding: 0 5%;
}
.topCol25 li img {
  width: 100%;
  height: auto;
}
.topCol26 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0 auto 0;
  width: 100%;
  position: relative;
  font-size: 1.2rem;
}
.topCol26 li:nth-child(1) {
  flex: 0 0 20%;
  margin: 0;
}
.topCol26 li:nth-child(2) {
  flex: 0 0 80%;
  margin: 0;
  padding: 0 5%;
}
.topCol26 li img {
  width: 100%;
  height: auto;
}

.topCol31 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto 0;
  margin: 2em auto 0;
  position: relative;
  font-size: 1.2rem;
}
.topCol31 li:nth-child(1) {
  flex: 0 0 45%;
  margin: 0;
}
.topCol31 li:nth-child(2) {
  flex: 0 0 55%;
  margin: 0;
}
.topCol31 li p {
  padding-left: 2em;
}
.topCol31 li img {
  width: 100%;
  height: auto;
}
.topCol32 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 2em auto 0;
  width: 90%;
  position: relative;
  font-size: 1.2rem;
}
.topCol32 li:nth-child(1) {
  flex: 0 0 70%;
  margin: 0 auto;
}
.topCol32 li:nth-child(2) {
  flex: 0 0 100%;
  margin: 2em 0;
  text-align: center;
}
.topCol32 li:nth-child(3) {
  flex: 0 0 100%;
  margin: 0;
  text-align: center;
}
.topCol32 li:nth-child(3) p {
  line-height: 2em;
}
.topCol32 li:nth-child(3) p strong {
  font-size: 1.6em;
}
.topCol32 li img {
  width: 30%;
  height: auto;
}
.wakaba {
  display: inline-block;
  height: 2.3em !important;
  width: auto !important;
  float: left;
  margin: 0.5em 0.5em 0 0;
}

.imgDropshadow {
  box-shadow: 0 0 8px rgba(30, 30, 30, 0.3);
}

#topbg1,
#topbg2,
#topbg3 {
  overflow: hidden;
}
#topbg1 label,
#topbg2 label,
#topbg3 label {
  cursor: pointer;
  border: 4px solid #111;
  font-size: 1em;
  border-radius: 8px;
  background-color: #fff;
  font-weight: bold;
  text-align: center;
  margin: 1em auto 0;
  padding: 5px 0;
  display: block;
  width: 7em;
  position: relative;
  transition: 0.25s;
}
#topbg1 input,
#topbg2 input,
#topbg3 input {
  display: none;
}
#topbg1 input + div#col1,
#topbg2 input + div#col2,
#topbg3 input + div#col3 {
  height: 0px;
  opacity: 0;
  transition: 0.25s; /* display:block; に transition は効かないから */
}
#topbg1 input:checked + div#col1,
#topbg2 input:checked + div#col2,
#topbg3 input:checked + div#col3 {
  height: auto;
  opacity: 1;
  transition: 1.25s;
}
#topbg1 input ~ label > span,
#topbg2 input ~ label > span,
#topbg3 input ~ label > span {
  display: inline;
}
#topbg1 input ~ label > span + span,
#topbg2 input ~ label > span + span,
#topbg3 input ~ label > span + span {
  display: none;
}
#topbg1 input:checked ~ label > span,
#topbg2 input:checked ~ label > span,
#topbg3 input:checked ~ label > span {
  display: none;
}
#topbg1 input:checked ~ label > span + span,
#topbg2 input:checked ~ label > span + span,
#topbg3 input:checked ~ label > span + span {
  display: inline-block;
}

p.fortuneCol1 {
  font-size: 1.2rem;
  width: 90%;
  margin: 2em auto 0;
  padding: 0;
}
.fortuneTable1 {
  width: 90%;
  margin: 2em auto 0;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(30, 30, 30, 0.3);
}
.fortuneTable1 tr:nth-child(even) {
  background-color: #fff9f9;
}
.fortuneTable1 td {
  border: 1px solid #999;
  padding: 0.5em;
}
.fortuneTable1 td:nth-child(1) {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}
.fortuneTable1 td:nth-child(2) {
  font-weight: bold;
  white-space: nowrap;
}
.fortuneTable1 td:nth-child(3) {
  width: auto;
}
p.fortuneCol2 {
  font-size: 1.2rem;
  width: 90%;
  margin: 2em auto 0;
  padding: 0;
}
.fortuneTable2 {
  width: 90%;
  margin: 2em auto 0;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(30, 30, 30, 0.3);
}
.fortuneTable2 tr:nth-child(even) {
  background-color: #ffffef;
}
.fortuneTable2 td {
  border: 1px solid #999;
  padding: 0.5em;
}
.fortuneTable2 td:nth-child(1) {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}
.fortuneTable2 td:nth-child(2) {
  width: auto;
}
.fortuneGraphAll {
  width: 90%;
  height: auto;
  margin: 2em auto 0;
  display: block;
}

section.termofservice h5 {
  margin: 3em 0 2em;
}
section.termofservice h2 {
  margin: 1em 0;
}

.transactionLogo {
  text-align: center;
}
.transactionLogo img {
  width: 70%;
  height: auto;
}

.tryagain {
  padding: 2em 0;
}

#descriptionBox {
  overflow: hidden;
}
#descriptionBox label {
  cursor: pointer;
  border: 4px solid #111;
  font-size: 1em;
  border-radius: 8px;
  background-color: #fff;
  font-weight: bold;
  text-align: center;
  margin: 2em auto;
  padding: 5px 0;
  display: block;
  width: 12em;
  position: relative;
  transition: 0.25s;
}
#descriptionBox input {
  display: none;
}
#descriptionBox input + div#col {
  height: 0px;
  opacity: 0;
  transition: 0.25s; /* display:block; に transition は効かないから */
}
#descriptionBox input:checked + div#col {
  height: auto;
  opacity: 1;
  transition: 1.25s;
}
#descriptionBox input ~ label > span {
  display: inline;
}
#descriptionBox input ~ label > span + span {
  display: none;
}
#descriptionBox input:checked ~ label > span {
  display: none;
}
#descriptionBox input:checked ~ label > span + span {
  display: inline-block;
}
#descriptionBox input:checked ~ label > i {
  transform: rotateX(180deg);
}
