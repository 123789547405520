@media screen and (max-width: 999px) {
  #sp-header {
    background: #000;
    box-shadow: 0 2px 8px rgba(30, 30, 80, 0.3);
    left: calc(50% - 150px);
    line-height: 1;
    position: fixed;
    top: 0;
    width: 300px;
    height: auto;
    z-index: 2400;
    font-size: 14px;
    color: #333;
    min-height: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }
  #sp-menu {
    margin: 0;
  }
  #sp-menu a {
    display: block;
    padding: 20px 10px;
    border-bottom: 1px solid #999;
  }
  #sp-menu a img {
    opacity: 0.8;
    width: auto;
    height: 30px;
  }
  #sp-menu a:hover img {
    opacity: 1;
    transition: 0.5s;
  }
  #sp-stateus {
  }
  #sp-account {
    width: 100%;
    padding: 20px 10px;
    color: #fff;
    display: block;
    border-bottom: 1px solid #999;
  }
  #sp-ticket {
    width: 100%;
    padding: 20px 10px;
    color: #fff;
    display: block;
    border-bottom: 1px solid #999;
  }
  #sp-account span,
  #sp-ticket span {
    font-weight: bold;
    color: #ff0;
  }
  #sp-lang {
    margin: 20px 10px;
    width: calc(100% - 20px);
  }
}
