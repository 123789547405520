#wrapper {
}
#topAnimWrap {
  position: relative;
  width: 100%;
  height: 75vw;
  margin: 0 auto;
}
#splashLogo {
  width: 380px;
  height: 380px;
  position: absolute;
  top: calc(50% - 190px);
  left: calc(50% - 190px);
  animation: splashLogo; /* アニメーション指定 */
  transform: translateY(-50%) translateX(-50%);
  transform-origin: 50% 50%;
  animation-delay: 0.5s;
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 101;
}
#splashLogo img {
  width: 100%;
  height: auto;
}
@keyframes splashLogo {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.5);
  }
  100% {
    opacity: 0;
    transform: scale(1);
    display: none;
  }
}
#topImageWrap {
  position: relative;
  top: 56px;
  left: 0;
  width: 100%;
  height: 75vw;
  z-index: 0;
}
#topImage1 {
  /*border:1px solid #f00;*/
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vw;
  background: url('/img/top-anim/anim1.svg') center center no-repeat;
  background-size: cover;
  z-index: 1;
  animation: topImageBG; /* アニメーション指定 */
  animation-delay: 0.1s;
  animation-duration: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}
#topImage2,
#topImage3 {
  position: absolute;
  z-index: 3;
  animation: topImage; /* アニメーション指定 */
  animation-delay: 1.5s;
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}
#topImage4,
#topImage5 {
  position: absolute;
  z-index: 5;
  animation: topImage; /* アニメーション指定 */
  animation-delay: 2s;
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}
#topImage6 {
  position: absolute;
  z-index: 6;
  animation: topImage; /* アニメーション指定 */
  animation-delay: 2.5s;
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}
#topImage2,
#topImage3,
#topImage4,
#topImage5,
#topImage6,
#topImage8 {
  width: 100%;
  /*height: 768px;*/
}
#topImage7 {
  width: 34%;
  height: auto;
}
#topImage2 img,
#topImage3 img,
#topImage4 img,
#topImage5 img,
#topImage6 img,
#topImage7 img,
#topImage8 img {
  display: inline-block;
}
@keyframes topImageBG {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes topImage {
  0% {
    opacity: 0;
    margin-top: 20px;
  }
  100% {
    opacity: 0.8;
    margin-top: 0;
  }
}
#topImage7 {
  position: absolute;
  z-index: 8;
  left: 33%;
  animation: topKanji; /* アニメーション指定 */
  animation-delay: 4s;
  animation-duration: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes topKanji {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
#topImage8 {
  position: absolute;
  z-index: 8;
  animation: topImage100; /* アニメーション指定 */
  animation-delay: 3s;
  animation-duration: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes topImage100 {
  0% {
    opacity: 0;
    margin-top: 20px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
#header,
#footer {
  animation: menuanim; /* アニメーション指定 */
  animation-delay: 0.1s;
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes menuanim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
