
.cp_ipselect07wh {
position: relative;
display: block;
overflow: hidden;
width: 100%;
height: 100%;
margin: 0 auto;
color: #111111;
}
.cp_ipselect07wh > i.toggle {
position: absolute;
z-index: 6;
top: 1.6em;
right: 1.5em;
color: #111111;
}
.cp_ipselect07wh .cp_sl07wh_arrowup:before {
position: absolute;
top: 0;
right: 0;
width: 0;
height: 0;
padding: 0;
content: '';
pointer-events: none;
border-right: 6px solid transparent;
border-bottom: 6px solid #111111;
border-left: 6px solid transparent;
-webkit-transition: all 250ms cubic-bezier(.4,.25,.3,1);
transition: all 250ms cubic-bezier(.4,.25,.3,1);
}
.cp_ipselect07wh .cp_sl07wh_arrowdown:before {
position: absolute;
top: 0;
right: 0;
width: 0;
height: 0;
padding: 0;
content: '';
pointer-events: none;
border-top: 6px solid #111111;
border-right: 6px solid transparent;
border-left: 6px solid transparent;
-webkit-transition: all 250ms cubic-bezier(.4,.25,.3,1);
transition: all 250ms cubic-bezier(.4,.25,.3,1);
}
.cp_ipselect07wh .cp_sl07wh_title, .cp_ipselect07wh .cp_sl07wh_selectlabel {
position: relative;
display: block;
width: 100%;
height: 100%;
padding: 1em 1em;
cursor: pointer;
border-top: 1px solid rgba(0, 0, 0, 0.05);
background: #eeeeee;
}
.cp_ipselect07wh > input {
position: absolute;
z-index: 3;
top: 0;
left: 0;
display: block;
width: 100%;
height: 100%;
cursor: pointer;
opacity: 0;
}
.cp_ipselect07wh > input:checked ~ i.toggle.cp_sl07wh_arrowdown {
display: none;
}
.cp_ipselect07wh > input:checked ~ i.toggle.cp_sl07wh_arrowup {
display: block;
}
.cp_ipselect07wh > input:checked div.options label.option .cp_sl07wh_title {
display: none !important;
}
.cp_ipselect07wh > input:not(:checked) {
z-index: 6;
}
.cp_ipselect07wh > input:not(:checked) ~ label.option > span.cp_sl07wh_title {
display: none;
}
.cp_ipselect07wh > input:not(:checked) ~ i.toggle.cp_sl07wh_arrowup {
display: none;
}
.cp_ipselect07wh > input:not(:checked) ~ i.toggle.cp_sl07wh_arrowdown {
display: block;
}
.cp_ipselect07wh > span.cp_sl07wh_selectlabel {
position: relative;
z-index: 2;
display: inline-block;
width: 100%;
color: #111111;
border-top: 0;
}
.cp_ipselect07wh label.option {
z-index: 13;
display: block;
overflow: hidden;
width: 100%;
transition: all 1s ease-out;
margin-bottom: 0;
padding: 0;
}
.cp_ipselect07wh label.option span.cp_sl07wh_title {
position: relative;
z-index: 4;
transition: 0.3s ease-out;
}
.cp_ipselect07wh label.option span.cp_sl07wh_title:hover {
color: #111111;
background: #cccccc;
}
.cp_ipselect07wh label.option input {
display: none;
}
.cp_ipselect07wh label.option input:checked ~ span.cp_sl07wh_title {
position: absolute;
z-index: 5;
top: 0;
display: block;
width: 100%;
color: inherit;
border-top: 0;
background: #eeeeee;
box-shadow: none;
}
