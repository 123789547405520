.cp_ipselect07 {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 3px;
  color: #ffffff;
}
.cp_ipselect07 > i.toggle {
  position: absolute;
  z-index: 6;
  top: 1.4em;
  right: 1em;
  color: #ffffff;
}
.cp_ipselect07 .cp_sl07_arrowup:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  pointer-events: none;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  -webkit-transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}
.cp_ipselect07 .cp_sl07_arrowdown:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  pointer-events: none;
  border-top: 6px solid #ffffff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}
.cp_ipselect07 .cp_sl07_title,
.cp_ipselect07 .cp_sl07_selectlabel {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 1em 1em;
  cursor: pointer;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background: #333333;
}
.cp_ipselect07 > input {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.cp_ipselect07 > input:checked ~ i.toggle.cp_sl07_arrowdown {
  display: none;
}
.cp_ipselect07 > input:checked ~ i.toggle.cp_sl07_arrowup {
  display: block;
}
.cp_ipselect07 > input:checked div.options label.option .cp_sl07_title {
  display: none !important;
}
.cp_ipselect07 > input:not(:checked) {
  z-index: 6;
}
.cp_ipselect07 > input:not(:checked) ~ label.option > span.cp_sl07_title {
  display: none;
}
.cp_ipselect07 > input:not(:checked) ~ i.toggle.cp_sl07_arrowup {
  display: none;
}
.cp_ipselect07 > input:not(:checked) ~ i.toggle.cp_sl07_arrowdown {
  display: block;
}
.cp_ipselect07 > span.cp_sl07_selectlabel {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 100%;
  color: #ffffff;
  border-top: 0;
}
.cp_ipselect07 label.option {
  z-index: 3;
  display: block;
  overflow: hidden;
  width: 100%;
  transition: all 1s ease-out;
  margin-bottom: 0;
  padding: 0;
}
.cp_ipselect07 label.option span.cp_sl07_title {
  position: relative;
  z-index: 4;
  transition: 0.3s ease-out;
}
.cp_ipselect07 label.option span.cp_sl07_title:hover {
  color: #ffffff;
  background: #222222;
}
.cp_ipselect07 label.option input {
  display: none;
}
.cp_ipselect07 label.option input:checked ~ span.cp_sl07_title {
  position: absolute;
  z-index: 5;
  top: 0;
  display: block;
  width: 100%;
  color: inherit;
  border-top: 0;
  background: #333333;
  box-shadow: none;
}
