#cookie-consent-more-link {
  color: rgb(255, 255, 255);
  margin-left: 6px;
  opacity: 0.8;
}

#cookie-consent-more-link:hover {
  opacity: 1;
}

#cookie-consent-button:hover {
  background-color: rgb(255, 235, 80) !important;
}
