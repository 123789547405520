.modal_wrap input {
  display: none;
}
.modal_overlay {
  display: flex;
  justify-content: center;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.5s, transform 0s 0.5s;
  transform: scale(0);
}
.modal_trigger {
  position: absolute;
  width: 100%;
  height: 100%;
}
.modal_content {
  position: relative;
  align-self: center;
  width: 90%;
  /*max-width: 640px;*/
  height: 90%;
  /*max-height: 960px;*/
  overflow-y: auto;
  padding: 1em;
  box-sizing: border-box;
  background: #fff;
  /*line-height: 1.4em;*/
  transition: 0.5s;
}
.modal_content h2 {
  margin-bottom: 10px;
}
.close_button {
  position: absolute;
  top: 14px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
}
.modal_wrap input:checked ~ .modal_overlay {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s;
}

.assetModalPart {
  background: #f6f6f6;
  padding: 10px;
}
@-moz-document url-prefix() {
  .modal_content .assetModalPart:last-child {
    margin-bottom: 2em;
  }
}

.assetModalPart:after {
  /*
	content: ".";
	display: block;
	height: 0;
	font-size: 0;
	clear: both;
	visibility: hidden; */
}
.assetModalPart h3 {
  margin: 0 0 10px 0;
}
.assetModalPart .assetImage {
  width: 65vw;
  height: 65vw;
  margin: 0 auto; /* 10px 30px 10px 10px*/
  /*float: left;*/
  position: relative;
  /*display: flex;
	display: table-cell;
	vertical-align: middle;*/
  text-align: center;
}
.assetModalPart .assetImage img {
  /*width: 512px;
height: 512px;
max-width: 100%;
max-height: 100%;
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;*/
  width: 100%;
  height: 100%;
  object-fit: contain; /* cover contain scale-down */
}
.assetModalPart .assetImage img {
  /*margin: auto;
	object-fit: scale-down;
	vertical-align: middle;
	display: inline;*/
}
.assetModalPart .assetCategory {
  font-size: 1.2em;
  line-height: 1.2em;
  padding: 5px;
  color: #333;
}
.assetModalPart .assetCategory img {
  height: 1.2em;
  width: auto;
  margin: 0 0.5em 5px 0; /*iconフォント行揃え*/
  display: inline-flex !important;
  vertical-align: middle !important;
}
.assetModalPart .assetName {
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}
.assetModalPart .assetData {
  padding-right: 10px;
}
.assetModalPart p {
  padding: 0;
  font-size: 1em;
  line-height: 1.5;
}
.assetModalPart table {
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 100%;
}
/*.assetModalPart table tr:nth-child(even) { background-color: #e6e6e6; }*/
.assetModalPart table tr td {
  padding: 0.2em 0.5em;
}
.assetModalPart table tr td:nth-child(1) {
  /*text-align: right;*/
  white-space: nowrap;
  background: #e3e3e3;
}
.assetModalPart table tr:nth-child(even) td:nth-child(1) {
  background: #d0d0d0;
}
.assetModalPart table tr td:nth-child(2) {
  word-break: break-all;
  background: #eeeeee;
}
.assetModalPart table tr:nth-child(even) td:nth-child(2) {
  background-color: #e3e3e3;
}
.buy-and-get {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 2em auto 1em;
}
.buy-and-get > .btnS {
  font-size: 1em;
  padding: 5px 0 10px;
  width: 130px;
}
.buy-and-get > .btnS span {
  display: block;
  font-size: 0.6em;
  line-height: 1;
}
.soldoutWord {
  text-align: center;
  font-weight: bold;
  color: #d00;
  margin: 2em 0;
}
.infoIcon {
  width: 20%;
  height: auto;
  margin: 0 1em 0 0;
  float: left;
}
